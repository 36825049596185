import React, { useEffect, useState } from "react"
import axios from "axios"
import Alert from "@material-ui/lab/Alert"
import { useTranslation } from "react-i18next"
import {
  Box,
  Grid,
  Paper,
  Container,
  Typography,
  makeStyles,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  TableFooter,
} from "@material-ui/core"

import SEO from "../components/seo"
import Sigin from "../components/signin"

import box from "../../static/images/Icons/box.svg"
import Done from "../../static/images/Icons/done.svg"
import phone from "../../static/images/Icons/phone.svg"
import boxBlue from "../../static/images/Icons/box-blue.svg"
import phoneBlue from "../../static/images/Icons/phone-blue.svg"
import PostOffice from "../../static/images/Icons/post-office.svg"
import DeliveryTruck from "../../static/images/Icons/delivery-truck.svg"
import PostOfficeBlue from "../../static/images/Icons/post-office-blue.svg"
import DeliveryTruckBlue from "../../static/images/Icons/delivery-truck-blue.svg"

const windowGlobal = typeof window !== "undefined" && window

const PaidOrder = () => {
  const classes = useStyles()
  const { t, i18n } = useTranslation()
  const [orderData, setOrderData] = useState()
  const [orderStatus, setOrderStatus] = useState()
  const [totalPrice, setTotalPrice] = useState(0)
  const [fixedPrice, setFixedPrice] = useState("")
  const [directAddUser, setDirectAddUser] = useState(false)
  const [totalAmount, setTotalAmount] = useState(0)
  const [discountPrice, setDiscountPrice] = useState(0)
  const [currencyRateState, setCurrencyRate] = useState()

  let jwtForProfile
  let orderId
  if (windowGlobal) {
    jwtForProfile = windowGlobal.sessionStorage.getItem("jwtCode")
    orderId = windowGlobal.location.search.substring(9)
  }

  let localCurrency = windowGlobal && windowGlobal.localStorage.getItem("currencyKey")

  useEffect(() => {
    axios
      .get(`${process.env.GATSBY_API_URL}/get-order/${orderId}`, {
        headers: {
          Authorization: `Bearer ${jwtForProfile}`,
          "Accept-Language": i18n.language,
          currency: localCurrency,
        },
      })
      .then(res => {
        // console.log(res.data)
        if (res.data) {
          setOrderData(res.data.order_items)
          setFixedPrice(
            res.data.custom_order && res.data.custom_order.fixedPrice > 0 ? res.data.custom_order.fixedPrice : ""
          )
          setDirectAddUser(res.data.custom_order && res.data.custom_order.directAddUser)
        }
        setOrderStatus(res.data.status)
      })
  }, [orderStatus, i18n, jwtForProfile, orderId])

  useEffect(() => {
    if (orderData) {
      setTotalPrice(
        orderData
          .map(item =>
            !item.discountedPrice > 0
              ? item.discountPrice > 0
                ? item.discountPrice * item.quantity
                : item.price * item.quantity
              : item.discountedPrice
          )
          .reduce((a, b) => a + b, 0)
      )
      setDiscountPrice(
        orderData
          .map(item => (item.discountPrice ? item.price - item.discountPrice : 0) * item.quantity)
          .reduce((a, b) => a + b, 0)
      )
      setTotalAmount(orderData.map(item => item.price * item.quantity).reduce((a, b) => a + b, 0))
    }
  }, [orderData])

  useEffect(() => {
    if (windowGlobal) {
      if (windowGlobal.localStorage.getItem("currencyKey") === "TRY") {
        setCurrencyRate(windowGlobal.sessionStorage.getItem("currencyRate"))
      }
    }
  }, [])

  const currencyIcon = currencyRateState > 1 ? <>&#8378;</> : <>&#36;</>

  return (
    <>
      <SEO title={t("paidOrder.title")} lang={i18n.language} />
      <Container maxWidth="md" className={`${classes.container}`}>
        <Box component="div" display={jwtForProfile ? `block` : `none`}>
          {orderData ? (
            <div>
              {!directAddUser ? (
                <Alert severity="success" className={classes.orderNoteSuccess}>
                  {t("orders.successPaid2")}
                </Alert>
              ) : (
                <Alert severity="success" className={classes.orderNoteSuccess}>
                  {/* {t("orders.successPaid2")}*/}
                  {t("orders.successPaidAndAddedTolibrary")}
                </Alert>
              )}
              <Typography variant="h5" component="p" className={classes.textColorBlue}>
                {t("myProfile.shipmentDetails")}
              </Typography>
              <br />
              <Grid container>
                <TableContainer component={Paper}>
                  <Table className={classes.table} aria-label="spanning table">
                    <TableHead className={classes.trackOrderTableHead}>
                      <TableRow>
                        <TableCell>{t("myProfile.bookName")}</TableCell>
                        <TableCell align="center">{t("myProfile.count")}</TableCell>
                        <TableCell align="center">{t("myProfile.price")}</TableCell>
                        <TableCell align="center">{t("myProfile.discount")}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {orderData &&
                        orderData.map(item => (
                          <TableRow key={item.book.id}>
                            <TableCell>{item.book.title}</TableCell>
                            <TableCell align="center">{item.quantity}</TableCell>
                            <TableCell align="center">
                              {!fixedPrice && (item.price ? item.price.toFixed(2) : item && item.price)} {""}
                              {!fixedPrice && currencyIcon}
                              {/* {currencyRateState > 1 ? <>&#8378;</> : <>&#36;</>} */}
                            </TableCell>
                            <TableCell align="center">
                              {item.discountPrice && (
                                <>
                                  {item.discountPrice.toFixed(2)}
                                  {currencyIcon}
                                </>
                              )}
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                    <TableFooter className={classes.trackOrderTableFooter}>
                      <TableRow>
                        <TableCell rowSpan={4} />
                        <TableCell align="left" colSpan={2}>
                          {t("myProfile.subtotal")}
                        </TableCell>
                        <TableCell align="center">
                          {!fixedPrice && totalAmount && (
                            <>
                              {totalAmount.toFixed(2)} {currencyIcon}
                            </>
                          )}
                        </TableCell>
                      </TableRow>
                      {!fixedPrice && (
                        <TableRow>
                          <TableCell align="left" colSpan={2}>
                            {t("myProfile.discountedPrice")}
                          </TableCell>
                          <TableCell align="center">
                            {discountPrice > 0 && (
                              <>
                                {discountPrice.toFixed(2)}
                                {currencyIcon}
                              </>
                            )}
                          </TableCell>
                        </TableRow>
                      )}
                      <TableRow>
                        <TableCell align="left" colSpan={2}>
                          {t("myProfile.total")}
                        </TableCell>
                        <TableCell align="center">
                          {fixedPrice && fixedPrice > 0 ? (
                            <>
                              {fixedPrice.toFixed(2)} {currencyIcon}
                            </>
                          ) : (
                            totalPrice && (
                              <>
                                {totalPrice.toFixed(2)} {currencyIcon}
                              </>
                            )
                          )}
                        </TableCell>
                      </TableRow>
                    </TableFooter>
                  </Table>
                </TableContainer>
              </Grid>
            </div>
          ) : (
            <Typography>
              {t("orders.NoOrderFound")}
              {/* {orderData && orderData.id} */}
            </Typography>
          )}
        </Box>
      </Container>
      <Box component="div" display={!jwtForProfile ? `block` : `none`}>
        <Container className={classes.notSignedIn}>
          <Typography variant="subtitle1">{t("myProfile.noPremissions")}</Typography>
          <br />
          <Sigin />
        </Container>
      </Box>
    </>
  )
}

export default PaidOrder

const useStyles = makeStyles(theme => ({
  container: {
    // height: "15vw",
    paddingTop: 40,
    marginTop: 20,
    marginBottom: 70,
  },
  textColorBlue: {
    color: "#018fff",
    textAlign: "center",
    [theme.breakpoints.up("sm")]: {},
  },
  trackOrderTableFooter: {
    "& .MuiTableCell-footer": {
      color: "rgba(0, 0, 0, 0.87)",
      fontSize: "0.875rem",
    },
    "& .MuiTableCell-alignLeft": {
      fontWeight: "600",
    },
  },
  trackOrderTableHead: {
    "& .MuiTableCell-head": {
      fontWeight: "600",
    },
  },
  notSignedIn: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    minHeight: "26vh",
    "& .MuiButtonBase-root": {
      backgroundColor: "#003e96",
      padding: "6px 12px",
      borderRadius: 10,
    },
  },
  orderNoteSuccess: {
    fontSize: 16,
    lineHeight: 1.8,
    width: "100%",
    marginBottom: 16,
    lineHeight: 1.8,
    fontSize: 16,
    "& .MuiAlert-message": {
      margin: "0 auto",
    },
    "& .MuiSvgIcon-root": {
      alignSelf: "center",
    },
  },
}))
